import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {Logo} from "../../../assets/images";

const Navbar = () => {
    return (
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center py-3 container">
            <Link to="/">
                <img src={Logo} width={120} alt="Logo" />
            </Link>
            <div className="mt-3 mt-md-0 d-flex flex-wrap flex-grow-1 justify-content-between justify-content-md-end align-items-center w-100">
                <Link
                    className="me-3 text-dark text-primary-hover text-decoration-none"
                    to="https://tournament.afa-sports.com/"
                    target="_blank"
                >
                    <span>Join Tournament</span>
                </Link>
                <Link
                    className="me-3 text-dark text-primary-hover text-decoration-none"
                    to="https://www.afa.community/afa-for-business/"
                    target="_blank"
                >
                    <span>AFA for Business</span>
                </Link>
                <Link
                    className="text-decoration-none"
                    to="https://play.google.com/store/apps/details?id=com.afausers&pcampaignid=web_share&pli=1"
                    target="_blank"
                >
                    <Button className="text-light rounded-pill fw-bold px-4" variant={"primary"}>
                        GET THE APP
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default Navbar;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import ReactGA from "react-ga4";

// bootstrap css
import "bootstrap/dist/css/bootstrap.min.css";

// Redux Store
import store from "./redux/store";

// SCSS
import "./assets/scss/index.scss";

// swiper css
import "swiper/css";
import "swiper/css/pagination";

// css for react-18-image-lightbox
import "react-18-image-lightbox/style.css";

ReactGA.initialize("G-1EJSYCWD3B");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()

serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", (event) => {
                if (event.target.state === "activated") {
                    window.location.reload();
                    // if (window.confirm("There is a new version of the app ready. Please reload to update.")) {
                    //   window.location.reload()
                    // }
                }
            });
            waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
        }
    },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
